<template>
  <v-app>
    <v-snackbar v-model="alert.show" :color="alert.color" top>
      <v-row class="py-0 my-0">
        <v-col cols="12" md="11" class="py-0 my-0">
          {{ alert.message }}
        </v-col>
        <v-col cols="12" md="1" class="py-0 my-0">
          <v-icon small dark @click="alert.show = false"> {{ mdiClose }} </v-icon>
        </v-col>
      </v-row>
    </v-snackbar>

    <v-main class="fill-height">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { mdiClose } from '@mdi/js'

export default {
  name: "blank",
  components: {},
  computed: {
    ...mapState({
      alert: (state) => state.alert.alert,
    }),
  },
  data() {
    return {
      mdiClose
    };
  },
};

</script>
